<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">แก้ไขสินค้า</h3>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_account_profile_details" class="collapse show">
            <!--begin::Form-->
            <Form
              v-if="product"
              @submit="onSubmitProduct"
              :validation-schema="productValidator"
              id="kt_account_profile_details_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <BaseInput name="id" type="hidden" v-model="product.id" />
                <BaseSelect
                  required="required"
                  label="ร้าน"
                  name="store_id"
                  type="number"
                  :data="market_saiyut_stores"
                  v-model="product.store_id"
                />

                <BaseSelect
                  required="required"
                  label="ประเภทสินค้า"
                  name="product_type_id"
                  type="number"
                  :data="market_saiyut_product_types"
                  v-model="product.product_type_id"
                />

                <BaseInput
                  label="ชื่อสินค้า"
                  required="required"
                  name="name"
                  type="text"
                  v-model="product.name"
                />

                <BaseImage
                  label="รูปหน้าแรก"
                  required="required"
                  name="image"
                  v-model="product.image"
                  :image="product.image"
                  :aspectRatio="1 / 1"
                  :height="800"
                  :width="800"
                />

                <BaseInput
                  required="required"
                  label="รายละเอียดสินค้า"
                  as="textarea"
                  name="detail"
                  v-model="product.detail"
                />

                <BaseInput
                  label="ราคา"
                  required="required"
                  type="number"
                  name="price"
                  v-model="product.price"
                />

                <BaseInput
                  label="ขนาดสินค้า"
                  name="size"
                  type="text"
                  v-model="product.size"
                />

                <BaseInput
                  label="น้ำหนักสินค้า"
                  type="text"
                  name="weight"
                  v-model="product.weight"
                />

                <BaseSelect
                  required="required"
                  label="สถานะ"
                  name="status"
                  type="text"
                  :data="status"
                  v-model="product.status"
                />
              </div>
              <!--end::Card body-->

              <!--begin::Actions-->
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> ยืนยัน </span>
                  <span class="indicator-progress">
                    กรุณารอสักครู่ ...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
      </div>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/modals/BaseImage.vue";
import BaseInput from "@/components/modals/BaseInput.vue";
import BaseSelect from "@/components/modals/BaseSelect.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useProduct from "@/core/services/api/travel/market_saiyut/product";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "market_saiyut-product-edit",
  components: {
    Form,
    BaseInput,
    BaseSelect,
    BaseImage,
  },
  props: ["id"],
  setup(props) {
    const router = useRouter();
    const { getProduct, updateProduct } = useProduct();
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const product = ref("");
    const market_saiyut_stores = ref("");
    const market_saiyut_product_types = ref("");
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const status = [
      { id: "แสดง", name: "แสดง" },
      { id: "ไม่แสดง", name: "ไม่แสดง" },
    ];

    const productValidator = Yup.object().shape({
      store_id: Yup.number()
        .required("กรุณาเลือก ร้าน")
        .typeError("กรุณากรอก ราคา"),
      product_type_id: Yup.number()
        .required("กรุณาเลือก ประเภทสินค้า")
        .typeError("กรุณากรอก ราคา"),
      name: Yup.string().required("กรุณากรอก ชื่อสินค้า"),
      image: Yup.string().required("กรุณาเลือก รูปภาพ"),
      detail: Yup.string().required("กรุณากรอก รายละเอียดสินค้า"),
      price: Yup.number()
        .required("กรุณากรอก ราคา")
        .typeError("กรุณากรอก ราคา"),
      status: Yup.string()
        .required("กรุณาเลือก สถานะ")
        .typeError("กรุณากรอก สถานะ"),
    });

    const onSubmitProduct = (values) => {
      Sconfirm("ยืนยัน การแก้ไขสินค้า", "question").then(async (result) => {
        if (result.isConfirmed) {
          if (submitButton.value) {
            // Activate indicator
            submitButton.value.setAttribute("data-kt-indicator", "on");
            submitButton.value.setAttribute("disabled", "disabled");
          }
          await updateProduct(values)
            .then(() => {
              SToast("success", "แก้ไข สินค้า สำเร็จ");
              submitButton.value?.removeAttribute("data-kt-indicator");
              submitButton.value?.removeAttribute("disabled");
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              submitButton.value?.removeAttribute("disabled");
              SconfirmNc(
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                "error",
                "ลองอีกครั้ง !"
              );
            });
        }
      });
    };

    onMounted(() => {
      if (!props.id) {
        return router.push({ name: "apps.travels.market_saiyut.product.index" });
      }
      getProduct(props.id)
        .then((response) => {
          product.value = response.data.data.product;
          market_saiyut_stores.value = response.data.data.market_saiyut_stores;
          market_saiyut_product_types.value = response.data.data.market_saiyut_product_types;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
        });

      checkPage("market_saiyut-product-type-create");
      setCurrentPageBreadcrumbs("แก้ไขสินค้า", [
        "ส่งเสริมการท่องเที่ยว",
        "ตลาดสายหยุด",
      ]);
    });

    return {
      product,
      productValidator,
      submitButton,
      onSubmitProduct,
      isLoading,
      color,
      market_saiyut_stores,
      market_saiyut_product_types,
      status,
    };
  },
});
</script>
<style scoped>
</style>
